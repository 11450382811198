import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyB5TUGLBKv1xHz13sgftM34De2IdK01DCo",
    authDomain: "poultry-5eabf.firebaseapp.com",
    projectId: "poultry-5eabf",
    storageBucket: "poultry-5eabf.appspot.com",
    messagingSenderId: "708267001300",
    appId: "1:708267001300:web:4df474acc1da8174f58241"
};

const app = initializeApp(firebaseConfig);
export const auth = getAuth();

export default getFirestore(app);